import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router-dom';
import { IMtacClaim } from '../../generated/clientApi';
import { getAccount, login, logout, getAuthType, setAuthType, setToken } from '../../modules/auth/auth';
import { getUserOperations } from '../../modules/roles/userOperations';
import { landingRoute } from '../../modules/routes/routes';
import { AuthContext } from './authContext';

export interface IAuthProps extends RouteProps {
  msalInstance: IPublicClientApplication;
}

export const AuthProvider: FunctionComponent<IAuthProps> = (props) => {
  const contextProps = {
    signin: async (tenantId: string | undefined) => login(tenantId),

    signout: async () => {
      const logoutTask = async () => {
        await logout();
        sessionStorage.clear();
        window.location.replace(landingRoute());
      };
      logoutTask();
    },

    isAuthorized: (requiredClaims: IMtacClaim[]) => {
      // list of type Claim(string: value/operation, string: issuer/organizationId )
      const claims = getUserOperations();
      if (claims) {
        if (
          requiredClaims.length &&
          requiredClaims.some((v) => !claims.find((claim) => claim.operation === v.operation && claim.subject === v.subject))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },

    user: async () => getAccount(),

    getAuthType,

    setToken,
    
    setAuthType,
  };

  return props.msalInstance ? (
    <MsalProvider instance={props.msalInstance}>
      <AuthContext.Provider value={contextProps}>{props.children}</AuthContext.Provider>
    </MsalProvider>
  ) : (
    <AuthContext.Provider value={contextProps}>{props.children}</AuthContext.Provider>
  );
};
