import { ApiException, CertAuthTokenGetClient } from "generated/authApi/clientApi";
import { getConfig } from "modules/config/config";
import { logError } from "modules/logging/logging";


export interface CertAuthTokenResponse {
   token?: string, 
   error?: string;
}

export const getCertAuthToken = async (): Promise<CertAuthTokenResponse> => {
  try {
    const client = new CertAuthTokenGetClient(getConfig().authApiBaseUri);
    const result = await client.get();
    return {token: result};
  } catch (error) {
    logError('An error occurred while getting cert authToken ', error);
    const apiError = error as ApiException;
    return { error: apiError.response };
  }
};