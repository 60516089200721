import { EventType, InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Icon, Label, mergeStyles, PrimaryButton, Stack } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { AuthContext, AuthType } from 'components/authProvider/authContext';
import { DialogModal } from 'components/dialog/dialog';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { Loading } from 'components/loading/loading';
import { TopNavBar } from 'components/topNavBar/topNavBar';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { CertAuthErrors } from 'models/certAuthErrors';
import { getCertAuthToken } from 'modules/auth/certAuth';
import { showDialogModal } from 'modules/dialog/dialog';
import { initializeUserOperations } from 'modules/roles/userOperations';
import { landingRoute } from 'modules/routes/routes';
import { LandingPageHeroContent } from 'pages/landing/landingPageHeroContent';
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

const mtacGreen = '#228186';

const cardStyles = mergeStyles({
  flexBasis: '20em',
  marginTop: '3em',
  alignItems: 'stretch',
  padding: '20px',
  boxShadow: `${NeutralColors.gray60} 0px 2px 4px`,
});

const loginButtonStyles = mergeStyles({
  margin: '0 0 1.3em 0',
  width: '29em',
  textAlign: 'left',
});

const separatorStyles = mergeStyles({
  width: '29em',
  height: '10px',
  margin: '0 5 0 5',
  borderBottom: `1px solid ${NeutralColors.black}`,
  textAlign: 'center',
});

const separatorTextStyles = mergeStyles({
  fontSize: '15px',
  backgroundColor: NeutralColors.white,
  padding: '0 10px',
});

const modalBodyStyle = mergeStyles({
  marginTop: '-20px',
  marginBottom: '20px',
});

const errorMessageStyles = mergeStyles({
  marginTop: '10px',
  color: '#ac5e5d',
  backgroundColor: '#e5cecd',
});

const stackStyles = mergeStyles({
  alignItems: 'center',
  marginLeft: '10em',
  marginRight: '10em',
});

export const LoginSelection: FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const [showAuthError, setShowAuthError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const heroContentContext = useRef(useContext(HeroContentContext));
  const isAuthenticated = useMtacIsAuthenticated();
  const { inProgress, instance } = useMsal();
  const [msalRedirectInProgress, setMsalRedirectInProgress] = useState<boolean>(false);

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(LandingPageHeroContent, false);

    const callbackId = instance.addEventCallback((message: { eventType: EventType; payload: any }) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        authContext.setAuthType(AuthType.AAD_TOKEN_AUTH);
        setMsalRedirectInProgress(true);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      authContext.setAuthType(AuthType.AAD_TOKEN_AUTH);
      fetchUserOperations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getLoginButton = (authType: AuthType, loginLinkText: string) => (
    <PrimaryButton
      className={loginButtonStyles}
      text={loginLinkText}
      style={
        authType === AuthType.CERT_AUTH
          ? {
              marginTop: '1.8em',
              marginBottom: '5em',
              backgroundColor: NeutralColors.white,
              color: NeutralColors.black,
              borderColor: NeutralColors.black,
            }
          : { backgroundColor: mtacGreen }
      }
      onClick={() => {
        authenticate(authType);
      }}
    >
      <Icon iconName="Forward" style={{ alignItems: 'right' }} />
    </PrimaryButton>
  );

  const fetchUserOperations = () => {
    initializeUserOperations().then((response) => {
      if (response.error || !response.userOperations) {
        authContext.setAuthType(AuthType.UNKNOWN);
      } else {
        window.open(landingRoute(), '_self');
      }
    });
  };

  const authenticateWithCert = () => {
    getCertAuthToken().then((response) => {
      if (response.token) {
        // success
        authContext.setToken(response.token);
        authContext.setAuthType(AuthType.CERT_AUTH);
        fetchUserOperations();
      } else {
        // handle errors
        authContext.setAuthType(AuthType.UNKNOWN);
        const { error } = response;
        if (error) {
          // parse and show backend errors
          const errorItems = error.split(':');
          if (errorItems.length === 2) {
            const [errorCode, errorMessage] = errorItems;
            if (errorCode !== CertAuthErrors.CERTAUTH2) {
              setShowAuthError(true);
              setErrorMessage(errorMessage || 'Browser error');
            } else {
              // show modal if user just onboarded
              showDialogModal(
                {},
                () => {
                  /* no-op */
                },
                <div className={modalBodyStyle}>{errorMessage}</div>,
                undefined,
                undefined,
                undefined,
                true,
                'Close',
              );
            }
          } else {
            // show unknown errors
            setShowAuthError(true);
            setErrorMessage(error || 'Browser error');
          }
        } else { 
            setShowAuthError(true);
            setErrorMessage(response.error || 'API error');
        }
      }
    });
  };

  const authenticate = async (authType: AuthType) => {
    setShowAuthError(false);
    switch (authType) {
      case AuthType.AAD_TOKEN_AUTH:
        authContext.signin().then(() => {
          authContext.setAuthType(authType);
          fetchUserOperations();
        });
        break;
      case AuthType.CERT_AUTH:
        authContext.setAuthType(AuthType.CERT_AUTH);
        authenticateWithCert();
        break;
      default:
      // no-op
    }
  };

  return (
    <>
      <TopNavBar hideLoginOptions />
      <DialogModal />
      <Stack className={stackStyles}>
        {inProgress !== InteractionStatus.None || msalRedirectInProgress ? (
          <Loading />
        ) : (
          <div className={cardStyles}>
            <Label style={{ fontSize: '25px' }}>Sign in</Label>
            <Label style={{ fontWeight: 'bold', marginTop: '3em' }}>Microsoft</Label>
            {getLoginButton(AuthType.AAD_TOKEN_AUTH, 'Sign in with Microsoft account')}
            <div className={separatorStyles}>
              <span className={separatorTextStyles}>or</span>
            </div>
            {getLoginButton(AuthType.CERT_AUTH, 'Sign in with gov certificate')}

            {showAuthError ? (
              <div className={errorMessageStyles}>
                {errorMessage}
                <p>Tip : try restarting your browser</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </Stack>
    </>
  );
};
