import { Icon, mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getPdfControlStyles, Navigation, updatePageNumber, updateScale, Zoom } from './pdfViewer.functions';

export interface PdfViewerProps {
  content?: any;
}

const pdfPageStyle = mergeStyles({
  marginLeft: '0em',
});

const cursorStyle = mergeStyles({
  cursor: 'pointer',
});

const pdfContainer = mergeStyles({
  border: '5px solid #f2f2f2',
});

const pdfDocumentContainerStyle = mergeStyles({
  minHeight: '800px',
  minWidth: '1000px',
});

const pdfControlsStyles = mergeStyles({
  display: 'flex',
  backgroundColor: '#f2f2f2',
  padding: '0.5em',
});

const largeNavStyles = mergeStyles(cursorStyle, {
  fontSize: '1.5em',
});

const controlContainer = mergeStyles({
  display: 'flex',
});

const leftNavContainerStyles = mergeStyles(cursorStyle, largeNavStyles, {
  margin: 'auto auto auto 1em',
});

const rightNavContainerStyles = mergeStyles(cursorStyle, largeNavStyles, {
  margin: 'auto 1em auto auto',
});

const zoomStyles = mergeStyles(cursorStyle, {
  fontSize: '2em',
  margin: 'auto',
  fontWeight: 'bold',
});

const leftZoomStyles = mergeStyles(zoomStyles, {
  margin: 'auto 0.33em auto auto',
});

const rightZoomStyles = mergeStyles(zoomStyles, {
  margin: 'auto auto auto 0.33em',
});

const smallNavStyles = mergeStyles(cursorStyle, {
  fontSize: '1.55em',
  fontWeight: 'bold',
});

const pageInfoStyles = mergeStyles({
  textAlign: 'center',
});

interface PdfInfo {
  numPages: any;
}

export const PdfViewer: FunctionComponent<PdfViewerProps> = (props: PdfViewerProps) => {
  const { content } = props;

  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState<{ data: any } | null>();
  const [scale, setScale] = useState(1.5);
  const [displayPdfControls, { setTrue: setDisplayPdfControlsTrue }] = useBoolean(false);
  const displayNavigation: boolean = pageCount > 1;

  // This is needed per a bug in react-pdf: https://github.com/wojtekmaj/react-pdf/issues/1540
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  // Needed to create this useEffect with the new react-pdf version due to mounting/unmounting issues: https://github.com/wojtekmaj/react-pdf/issues/1539
  useEffect(() => {
    setPdfData({ data: content as ArrayBuffer });
  }, [content]);

  const onDocumentLoadSuccess = (pdfInfo: PdfInfo) => {
    setPageCount(pdfInfo.numPages);
    setDisplayPdfControlsTrue();
  };

  const onNavigationClick = (nav: Navigation) => {
    setPageNumber(updatePageNumber(pageNumber, pageCount, nav));
  };

  const onZoomClick = (zoom: Zoom) => {
    setScale(updateScale(scale, zoom));
  };

  const PdfControls = () => {
    if (!displayPdfControls) {
      return <></>;
    }
    return (
      <div className={mergeStyles(pdfControlsStyles, { justifyContent: getPdfControlStyles(displayNavigation) })}>
        {displayNavigation && (
          <div className={controlContainer}>
            <Icon className={leftNavContainerStyles} iconName="ChevronLeftEnd6" title="Start" onClick={() => onNavigationClick(Navigation.Start)} />
            <Icon
              className={mergeStyles(leftNavContainerStyles, smallNavStyles)}
              iconName="ChevronLeft"
              title="Previous"
              onClick={() => onNavigationClick(Navigation.Previous)}
            />
          </div>
        )}
        <div>
          <Icon className={leftZoomStyles} iconName="ZoomOut" title="Zoom Out" onClick={() => onZoomClick(Zoom.Out)} />
          <Icon className={rightZoomStyles} iconName="ZoomIn" title="Zoom In" onClick={() => onZoomClick(Zoom.In)} />
        </div>
        {displayNavigation && (
          <div className={controlContainer}>
            <Icon
              className={mergeStyles(rightNavContainerStyles, smallNavStyles)}
              iconName="ChevronRight"
              title="Next"
              onClick={() => onNavigationClick(Navigation.Next)}
            />
            <Icon className={rightNavContainerStyles} iconName="ChevronRightEnd6" title="End" onClick={() => onNavigationClick(Navigation.End)} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={pdfContainer}>
      <PdfControls />
      {displayPdfControls ? <p className={pageInfoStyles}>{`Page ${pageNumber} of ${pageCount}`}</p> : <></>}
      <div className={pdfDocumentContainerStyle}>
        <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} loading={<Spinner size={SpinnerSize.medium} />}>
          <Page className={pdfPageStyle} pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
      {displayPdfControls ? <p className={pageInfoStyles}>{`Page ${pageNumber} of ${pageCount}`}</p> : <></>}
      <PdfControls />
    </div>
  );
};
