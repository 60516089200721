import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType } from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import { BasicPoam, PlannedMilestone, PoamPutClient, PoamPutCommand, PoamPutCommandPoam, PoamPutRemediation } from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import React, { useCallback, useMemo, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { showSuccess } from 'modules/messageBar/messageBar';
import { RemediationForm } from '../poamPanel/remediationForm';

export type RemediationInternalComments = PoamPutRemediation & { internalComments?: string };

type BulkEditPanelProps = {
  poamSelection: BasicPoam[];
  isOpen: boolean;
  close: () => void;
  selectedPeriod: string;
  updatePoams: () => void;
};
export const BulkEditPanel: React.FunctionComponent<BulkEditPanelProps> = (props) => {
  const { poamSelection, selectedPeriod, isOpen, close, updatePoams } = props;

  const [remediationInfo, setRemediationInfo] = useState<RemediationInternalComments>({} as RemediationInternalComments);
  const [isSaving, setSaving] = useState(false);
  const [messageBarMessages, setMessageBarMessages] = useState({ success: '', error: '' });

  const wasEdited = useMemo(() => Object.values(remediationInfo).some((value) => value !== undefined), [remediationInfo]);

  const onSave = useCallback(async () => {
    setSaving(true);
    const client = new PoamPutClient(getConfig().apiBaseUri);
    const updatedPoams =
      poamSelection.map(
        (poam) =>
          ({
            ...poam,
            remediationInfo,
            internalComments: remediationInfo.internalComments,
          }) as PoamPutCommandPoam,
      ) ?? [];
    try {
      const putCommand = {
        period: selectedPeriod,
        poams: updatedPoams,
        isBulkEdit: true,
      };
      const response = await client.put(putCommand as PoamPutCommand);
      updatePoams();
      showSuccess(`${response.length} POA&M(s) successfully saved`);
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: 'There was an error saving POA&Ms. Please refresh to try again.',
      });
    } finally {
      setSaving(false);
      close();
    }
  }, [close, poamSelection, remediationInfo, selectedPeriod, updatePoams]);

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ position: 'absolute', bottom: '1rem' }}>
        <SaveButton defaultText="Save" saveText="Saving..." onSave={onSave} isSaving={isSaving} disabled={!wasEdited}>
          Save
        </SaveButton>
        <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [close, isSaving, onSave, wasEdited],
  );

  const updateRemediation = (key: keyof RemediationInternalComments, value?: string | PlannedMilestone[]): void => {
    const newRemediationInfo = { ...remediationInfo, [key]: value } as RemediationInternalComments;
    if (newRemediationInfo?.milestoneHistory) {
      newRemediationInfo.plannedRemediationDate = newRemediationInfo.milestoneHistory.at(-1)?.plannedRemediationDate;
    } else {
      newRemediationInfo.plannedRemediationDate = '';
    }
    if (newRemediationInfo?.milestoneHistory) {
      newRemediationInfo.comment = newRemediationInfo.milestoneHistory.at(-1)?.comment;
    } else {
      newRemediationInfo.comment = '';
    }
    setRemediationInfo(newRemediationInfo);
  };

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={close}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      headerText={`Bulk edit (${poamSelection.length}) selected`}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      {(messageBarMessages.success || messageBarMessages.error) && (
        <MessageBar
          messageBarType={messageBarMessages.success ? MessageBarType.success : MessageBarType.error}
          onDismiss={() => setMessageBarMessages({ success: '', error: '' })}
          dismissButtonAriaLabel="Dismiss"
        >
          {messageBarMessages.success || messageBarMessages.error}
        </MessageBar>
      )}
      <RemediationForm updateRemediation={updateRemediation} />
    </Panel>
  );
};
